import React from 'react';
import classNames from 'classnames';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import svgStyles from '../../../stylesheets/components/svg.module.scss';

export const LightModeLoginPMLogoIcon = () => {
  return (
    <svg
      className="light"
      width="156"
      height="28"
      viewBox="0 0 156 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0023 3.75037L28.0783 0V24.5066L7.62982e-05 24.5064V22.9642L11.0009 21.5236V21.5139L11.0499 21.5172L11.0764 21.5137V21.519L26.2958 22.5533V21.2016L11.0503 19.4385L7.17035e-05 21.9114V20.4164L11.0009 16.4671V16.4401L11.0506 16.4492L11.0764 16.44V16.454L26.2958 19.2483V17.8967L11.0507 14.3705L0 19.3719V17.8539L11.0009 11.4105V11.3663L11.0507 11.3812L11.0764 11.3662V11.3889L26.2958 15.9434V14.5886L11.0507 9.27293L8.13462e-05 16.793L3.41584e-05 15.2723L11.0009 6.3242V6.26285L11.0507 6.28363L11.0764 6.26272V6.29434L22.0023 10.8465V3.75037ZM31.6685 24.5066H29.8735V1.61763L31.6685 3.25327V24.5066ZM33.1036 24.5066V4.56102L34.5387 5.86869V24.5066H33.1036Z"
        fill="#1472FF"
      ></path>
      <path
        d="M47.5395 13.3784H42.7053V24.5023H44.7903V20.6121H47.5395C50.0724 20.6121 51.4315 19.1435 51.4315 17.0031C51.4315 14.847 50.0724 13.3784 47.5395 13.3784ZM47.4468 18.7998H44.7903V15.1907H47.4468C48.6669 15.1907 49.331 15.8469 49.331 17.0031C49.331 18.1592 48.6669 18.7998 47.4468 18.7998Z"
        fill="#121F2C"
      ></path>
      <path
        d="M56.7993 16.3156C55.5637 16.3156 54.807 16.9718 54.4208 17.878V16.4406H52.5366V24.5023H54.4826V20.409C54.4826 18.8779 55.3475 18.1592 56.7839 18.1592H57.2781V16.3469C57.1082 16.3156 56.9692 16.3156 56.7993 16.3156Z"
        fill="#121F2C"
      ></path>
      <path
        d="M61.673 24.6898C64.0978 24.6898 65.7195 23.0337 65.7195 20.4715C65.7195 17.9092 64.0978 16.2531 61.673 16.2531C59.2482 16.2531 57.6111 17.9092 57.6111 20.4715C57.6111 23.0337 59.2482 24.6898 61.673 24.6898ZM61.673 23.065C60.4066 23.065 59.6035 22.1744 59.6035 20.4715C59.6035 18.7685 60.4066 17.878 61.673 17.878C62.924 17.878 63.7426 18.7685 63.7426 20.4715C63.7426 22.1744 62.924 23.065 61.673 23.065Z"
        fill="#121F2C"
      ></path>
      <path
        d="M71.3576 16.2531C70.1992 16.2531 69.3498 16.7531 68.8092 17.5968V16.4406H66.9405V27.5645H68.8865V23.4556C69.427 24.2367 70.2456 24.6898 71.3576 24.6898C73.5507 24.6898 74.9253 23.0337 74.9253 20.4715C74.9253 17.9092 73.5507 16.2531 71.3576 16.2531ZM70.8943 23.065C69.6278 23.065 68.8401 22.1744 68.8401 20.4715C68.8401 18.7685 69.6278 17.878 70.8943 17.878C72.1607 17.878 72.9484 18.706 72.9484 20.409C72.9484 22.1119 72.1607 23.065 70.8943 23.065Z"
        fill="#121F2C"
      ></path>
      <path
        d="M83.6623 20.6121C83.6623 18.0498 82.1642 16.2531 79.7239 16.2531C77.3609 16.2531 75.7856 17.9405 75.7856 20.4715C75.7856 23.0494 77.4073 24.6898 79.7548 24.6898C81.67 24.6898 83.06 23.6431 83.4615 22.0026H81.5927C81.2993 22.7213 80.6969 23.1587 79.7703 23.1587C78.6428 23.1587 77.8397 22.5182 77.7007 21.0808H83.6623V20.6121ZM79.7085 17.7842C80.8668 17.7842 81.5618 18.5654 81.7163 19.7059H77.7161C77.9015 18.3779 78.6737 17.7842 79.7085 17.7842Z"
        fill="#121F2C"
      ></path>
      <path
        d="M89.1815 16.3156C87.946 16.3156 87.1892 16.9718 86.8031 17.878V16.4406H84.9189V24.5023H86.8649V20.409C86.8649 18.8779 87.7298 18.1592 89.1661 18.1592H89.6603V16.3469C89.4904 16.3156 89.3514 16.3156 89.1815 16.3156Z"
        fill="#121F2C"
      ></path>
      <path
        d="M94.9405 17.9405V16.4406H93.3652V14.0971H91.4346V16.4406H90.1991V17.9405H91.4346V22.5025C91.4346 23.9868 92.2378 24.5648 93.9212 24.5648C94.2301 24.5648 94.5853 24.5336 94.9405 24.5023V23.0025H94.2764C93.6278 23.0025 93.3652 22.8306 93.3652 22.2994V17.9405H94.9405Z"
        fill="#121F2C"
      ></path>
      <path
        d="M101.285 16.4406L100.636 18.4717C100.204 19.8934 99.7712 21.362 99.3542 22.9556C98.9526 21.3776 98.5202 19.8622 98.0877 18.4561L97.4391 16.4406H95.3386L97.6553 23.065C97.8406 23.5962 98.026 24.2055 98.026 24.6898C98.026 25.3616 97.6707 25.9866 96.7132 25.9866H95.9718V27.5333C96.2189 27.5645 96.4506 27.5802 96.8213 27.5802C98.7518 27.5802 99.6167 26.9552 100.25 25.1585L103.323 16.4406H101.285Z"
        fill="#121F2C"
      ></path>
      <path
        d="M116.27 13.3784L114.432 18.6748C114.093 19.6747 113.737 20.8308 113.459 21.7995C113.181 20.8777 112.842 19.784 112.517 18.7841L110.618 13.3784H107.59V24.5023H109.598V21.112C109.598 19.4403 109.583 17.628 109.536 15.6282C109.784 16.4562 110.031 17.2062 110.355 18.1592L112.594 24.5023H114.309L116.533 18.1592C116.873 17.1905 117.12 16.4406 117.367 15.597C117.321 17.6124 117.305 19.4247 117.305 21.112V24.5023H119.297V13.3784H116.27Z"
        fill="#121F2C"
      ></path>
      <path
        d="M128.057 24.5023C127.841 24.1899 127.733 23.5493 127.733 22.9712V18.6748C127.733 17.2218 126.791 16.2531 124.412 16.2531C121.926 16.2531 120.937 17.2999 120.829 19.0029H122.744C122.821 18.0186 123.285 17.7217 124.397 17.7217C125.524 17.7217 125.848 18.0967 125.848 18.7217C125.848 19.3153 125.462 19.5497 124.721 19.6434L123.516 19.7528C121.261 19.9715 120.458 20.9246 120.458 22.2682C120.458 23.8305 121.632 24.6898 123.331 24.6898C124.505 24.6898 125.37 24.268 125.895 23.5181C125.926 23.9086 125.987 24.2836 126.111 24.5023H128.057ZM123.794 23.2837C122.899 23.2837 122.451 22.8619 122.451 22.1901C122.451 21.5495 122.806 21.1589 123.887 21.0339L124.937 20.9089C125.354 20.8621 125.648 20.7683 125.848 20.5808V21.3152C125.848 22.7213 124.829 23.2837 123.794 23.2837Z"
        fill="#121F2C"
      ></path>
      <path
        d="M133.13 17.9405V16.4406H131.554V14.0971H129.624V16.4406H128.388V17.9405H129.624V22.5025C129.624 23.9868 130.427 24.5648 132.11 24.5648C132.419 24.5648 132.774 24.5336 133.13 24.5023V23.0025H132.465C131.817 23.0025 131.554 22.8306 131.554 22.2994V17.9405H133.13Z"
        fill="#121F2C"
      ></path>
      <path
        d="M138.532 16.3156C137.296 16.3156 136.539 16.9718 136.153 17.878V16.4406H134.269V24.5023H136.215V20.409C136.215 18.8779 137.08 18.1592 138.516 18.1592H139.01V16.3469C138.841 16.3156 138.702 16.3156 138.532 16.3156Z"
        fill="#121F2C"
      ></path>
      <path
        d="M140.076 15.3938H141.991V13.5503H140.076V15.3938ZM140.061 24.5023H142.007V16.4406H140.061V24.5023Z"
        fill="#121F2C"
      ></path>
      <path
        d="M147.84 20.3621L150.651 16.4406H148.474L148.057 17.0343C147.64 17.6593 147.192 18.3154 146.79 18.9404C146.373 18.2998 145.956 17.6749 145.508 17.0343L145.091 16.4406H142.867L145.678 20.3777L142.713 24.5023H144.891L145.184 24.0805C145.709 23.3306 146.234 22.5494 146.744 21.7995C147.254 22.5494 147.763 23.315 148.288 24.0805L148.582 24.5023H150.806L147.84 20.3621Z"
        fill="#121F2C"
      ></path>
      <path
        d="M152.403 13.2317V13.5877H152.901V15.0245H153.284V13.5877H153.782V13.2317H152.403Z"
        fill="#121F2C"
      ></path>
      <path
        d="M155.013 14.4713L154.524 13.2317H154.02V15.0245H154.385V13.7926L154.871 15.0245H155.139L155.625 13.7849V15.0245H156V13.2317H155.504L155.013 14.4713Z"
        fill="#121F2C"
      ></path>
    </svg>
  );
};

export const DarkModeLoginPMLogoIcon = () => {
  return (
    <svg
      className="dark"
      width="156"
      height="28"
      viewBox="0 0 156 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0023 3.75037L28.0783 0V24.5066L7.62982e-05 24.5064V22.9642L11.0009 21.5236V21.5139L11.0499 21.5172L11.0764 21.5137V21.519L26.2958 22.5533V21.2016L11.0503 19.4385L7.17035e-05 21.9114V20.4164L11.0009 16.4671V16.4401L11.0506 16.4492L11.0764 16.44V16.454L26.2958 19.2483V17.8967L11.0507 14.3705L0 19.3719V17.8539L11.0009 11.4105V11.3663L11.0507 11.3812L11.0764 11.3662V11.3889L26.2958 15.9434V14.5886L11.0507 9.27293L8.13462e-05 16.793L3.41584e-05 15.2723L11.0009 6.3242V6.26285L11.0507 6.28363L11.0764 6.26272V6.29434L22.0023 10.8465V3.75037ZM31.6685 24.5066H29.8735V1.61763L31.6685 3.25327V24.5066ZM33.1036 24.5066V4.56102L34.5387 5.86869V24.5066H33.1036Z"
        fill="#1472FF"
      ></path>
      <path
        d="M47.5395 13.3784H42.7053V24.5023H44.7903V20.6121H47.5395C50.0724 20.6121 51.4315 19.1435 51.4315 17.0031C51.4315 14.847 50.0724 13.3784 47.5395 13.3784ZM47.4468 18.7998H44.7903V15.1907H47.4468C48.6669 15.1907 49.331 15.8469 49.331 17.0031C49.331 18.1592 48.6669 18.7998 47.4468 18.7998Z"
        fill="white"
      ></path>
      <path
        d="M56.7993 16.3156C55.5637 16.3156 54.807 16.9718 54.4208 17.878V16.4406H52.5366V24.5023H54.4826V20.409C54.4826 18.8779 55.3475 18.1592 56.7839 18.1592H57.2781V16.3469C57.1082 16.3156 56.9692 16.3156 56.7993 16.3156Z"
        fill="white"
      ></path>
      <path
        d="M61.673 24.6898C64.0978 24.6898 65.7195 23.0337 65.7195 20.4715C65.7195 17.9092 64.0978 16.2531 61.673 16.2531C59.2482 16.2531 57.6111 17.9092 57.6111 20.4715C57.6111 23.0337 59.2482 24.6898 61.673 24.6898ZM61.673 23.065C60.4066 23.065 59.6035 22.1744 59.6035 20.4715C59.6035 18.7685 60.4066 17.878 61.673 17.878C62.924 17.878 63.7426 18.7685 63.7426 20.4715C63.7426 22.1744 62.924 23.065 61.673 23.065Z"
        fill="white"
      ></path>
      <path
        d="M71.3576 16.2531C70.1992 16.2531 69.3498 16.7531 68.8092 17.5968V16.4406H66.9405V27.5645H68.8865V23.4556C69.427 24.2367 70.2456 24.6898 71.3576 24.6898C73.5507 24.6898 74.9253 23.0337 74.9253 20.4715C74.9253 17.9092 73.5507 16.2531 71.3576 16.2531ZM70.8943 23.065C69.6278 23.065 68.8401 22.1744 68.8401 20.4715C68.8401 18.7685 69.6278 17.878 70.8943 17.878C72.1607 17.878 72.9484 18.706 72.9484 20.409C72.9484 22.1119 72.1607 23.065 70.8943 23.065Z"
        fill="white"
      ></path>
      <path
        d="M83.6623 20.6121C83.6623 18.0498 82.1642 16.2531 79.7239 16.2531C77.3609 16.2531 75.7856 17.9405 75.7856 20.4715C75.7856 23.0494 77.4073 24.6898 79.7548 24.6898C81.67 24.6898 83.06 23.6431 83.4615 22.0026H81.5927C81.2993 22.7213 80.6969 23.1587 79.7703 23.1587C78.6428 23.1587 77.8397 22.5182 77.7007 21.0808H83.6623V20.6121ZM79.7085 17.7842C80.8668 17.7842 81.5618 18.5654 81.7163 19.7059H77.7161C77.9015 18.3779 78.6737 17.7842 79.7085 17.7842Z"
        fill="white"
      ></path>
      <path
        d="M89.1815 16.3156C87.946 16.3156 87.1892 16.9718 86.8031 17.878V16.4406H84.9189V24.5023H86.8649V20.409C86.8649 18.8779 87.7298 18.1592 89.1661 18.1592H89.6603V16.3469C89.4904 16.3156 89.3514 16.3156 89.1815 16.3156Z"
        fill="white"
      ></path>
      <path
        d="M94.9405 17.9405V16.4406H93.3652V14.0971H91.4346V16.4406H90.1991V17.9405H91.4346V22.5025C91.4346 23.9868 92.2378 24.5648 93.9212 24.5648C94.2301 24.5648 94.5853 24.5336 94.9405 24.5023V23.0025H94.2764C93.6278 23.0025 93.3652 22.8306 93.3652 22.2994V17.9405H94.9405Z"
        fill="white"
      ></path>
      <path
        d="M101.285 16.4406L100.636 18.4717C100.204 19.8934 99.7712 21.362 99.3542 22.9556C98.9526 21.3776 98.5202 19.8622 98.0877 18.4561L97.4391 16.4406H95.3386L97.6553 23.065C97.8406 23.5962 98.026 24.2055 98.026 24.6898C98.026 25.3616 97.6707 25.9866 96.7132 25.9866H95.9718V27.5333C96.2189 27.5645 96.4506 27.5802 96.8213 27.5802C98.7518 27.5802 99.6167 26.9552 100.25 25.1585L103.323 16.4406H101.285Z"
        fill="white"
      ></path>
      <path
        d="M116.27 13.3784L114.432 18.6748C114.093 19.6747 113.737 20.8308 113.459 21.7995C113.181 20.8777 112.842 19.784 112.517 18.7841L110.618 13.3784H107.59V24.5023H109.598V21.112C109.598 19.4403 109.583 17.628 109.536 15.6282C109.784 16.4562 110.031 17.2062 110.355 18.1592L112.594 24.5023H114.309L116.533 18.1592C116.873 17.1905 117.12 16.4406 117.367 15.597C117.321 17.6124 117.305 19.4247 117.305 21.112V24.5023H119.297V13.3784H116.27Z"
        fill="white"
      ></path>
      <path
        d="M128.057 24.5023C127.841 24.1899 127.733 23.5493 127.733 22.9712V18.6748C127.733 17.2218 126.791 16.2531 124.412 16.2531C121.926 16.2531 120.937 17.2999 120.829 19.0029H122.744C122.821 18.0186 123.285 17.7217 124.397 17.7217C125.524 17.7217 125.848 18.0967 125.848 18.7217C125.848 19.3153 125.462 19.5497 124.721 19.6434L123.516 19.7528C121.261 19.9715 120.458 20.9246 120.458 22.2682C120.458 23.8305 121.632 24.6898 123.331 24.6898C124.505 24.6898 125.37 24.268 125.895 23.5181C125.926 23.9086 125.987 24.2836 126.111 24.5023H128.057ZM123.794 23.2837C122.899 23.2837 122.451 22.8619 122.451 22.1901C122.451 21.5495 122.806 21.1589 123.887 21.0339L124.937 20.9089C125.354 20.8621 125.648 20.7683 125.848 20.5808V21.3152C125.848 22.7213 124.829 23.2837 123.794 23.2837Z"
        fill="white"
      ></path>
      <path
        d="M133.13 17.9405V16.4406H131.554V14.0971H129.624V16.4406H128.388V17.9405H129.624V22.5025C129.624 23.9868 130.427 24.5648 132.11 24.5648C132.419 24.5648 132.774 24.5336 133.13 24.5023V23.0025H132.465C131.817 23.0025 131.554 22.8306 131.554 22.2994V17.9405H133.13Z"
        fill="white"
      ></path>
      <path
        d="M138.532 16.3156C137.296 16.3156 136.539 16.9718 136.153 17.878V16.4406H134.269V24.5023H136.215V20.409C136.215 18.8779 137.08 18.1592 138.516 18.1592H139.01V16.3469C138.841 16.3156 138.702 16.3156 138.532 16.3156Z"
        fill="white"
      ></path>
      <path
        d="M140.076 15.3938H141.991V13.5503H140.076V15.3938ZM140.061 24.5023H142.007V16.4406H140.061V24.5023Z"
        fill="white"
      ></path>
      <path
        d="M147.84 20.3621L150.651 16.4406H148.474L148.057 17.0343C147.64 17.6593 147.192 18.3154 146.79 18.9404C146.373 18.2998 145.956 17.6749 145.508 17.0343L145.091 16.4406H142.867L145.678 20.3777L142.713 24.5023H144.891L145.184 24.0805C145.709 23.3306 146.234 22.5494 146.744 21.7995C147.254 22.5494 147.763 23.315 148.288 24.0805L148.582 24.5023H150.806L147.84 20.3621Z"
        fill="white"
      ></path>
      <path
        d="M152.403 13.2317V13.5877H152.901V15.0245H153.284V13.5877H153.782V13.2317H152.403Z"
        fill="white"
      ></path>
      <path
        d="M155.013 14.4713L154.524 13.2317H154.02V15.0245H154.385V13.7926L154.871 15.0245H155.139L155.625 13.7849V15.0245H156V13.2317H155.504L155.013 14.4713Z"
        fill="white"
      ></path>
    </svg>
  );
};
